import React from 'react';
import ReactDOM from 'react-dom';

import GDPR from '../GDPR';
import ThemeWrap from '../ThemeWrap';
import PageNotFound from './PageNotFound';

ReactDOM.render(
  <ThemeWrap>
    <GDPR />
    <PageNotFound />
  </ThemeWrap>,
  document.getElementById('root')
);
