import './PageNotFound.module.css';

import React from 'react';

import NavigationWrap from '../../NavigationWrap';

export default function PageNotFound() {
  return (
    <NavigationWrap>
      <div styleName="wrap">
        <h1>Page not found</h1>
      </div>
    </NavigationWrap>
  );
}
